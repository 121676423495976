import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { CrmDirectory } from './listDirectoryEntries/CrmDirectory';
import { CrmCompanyCreate } from './newCompany/CrmCompanyCreate';
import { CrmContactCreate } from './newContact/CrmContactCreate';
import { CrmCompanyEdit } from './editCompany/CrmCompanyEdit';

export interface CRMRoutingProps extends RouteComponentProps {}

export const CRMRouting = (props: CRMRoutingProps) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={CrmDirectory} />
      <Route exact path={`${props.match.path}/company/create`} component={CrmCompanyCreate} />
      <Route exact path={`${props.match.path}/contact/create`} component={CrmContactCreate} />
      <Route exact path={`${props.match.path}/company/:companyId/edit`} component={CrmCompanyEdit} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(CRMRouting);
