import React from 'react';
import { SimpleTabs } from 'ui/SimpleTabs';
import { CrmCompanyDocuments } from './CrmCompanyDocuments';
import { CrmCompanyMainInfo } from './CrmCompanyMainInfo';
import { IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';

interface ICrmCompanyTabsProps {
  directoryEntry: IDirectoryEntry;
}

export const CrmCompanyTabs: React.FC<ICrmCompanyTabsProps> = ({ directoryEntry }) => {
    const crmCompanyTabs = [
      {
        title: 'Overview',
        name: 'overview',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmCompanyMainInfo directoryEntryId={directoryEntry.id}  />,
      },
      {
        title: 'Documents',
        name: 'documents',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmCompanyDocuments   />,
      },
    ];
  
    return (
      <SimpleTabs tabConfig={crmCompanyTabs} className="o:w-full o:p-0" tabSelectorClassName="o:m-0" />
    );
}