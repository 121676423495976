import classNames from 'classnames';
import React from 'react';
import Flag from 'react-world-flags';
import companyLogoPlaceholder from '../../../../public/assets/img/company-logo-placeholder.png';
import { ECrmCompanyType, ICrmCompanyProfile, IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';
import { formatDateTimeDisplay } from 'utils';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';

export interface ICompanyPageTitleProps {
  primaryText: string;
  countryCode: string;
  companyType: string;
  primaryClassName?: string;
  secondaryClassName?: string;
  loadingClassName?: string;
  secondaryCondition?: boolean;
  loadingCondition?: boolean;
  directoryEntry?: IDirectoryEntry;
}

export const CompanyPageTitle: React.FC<ICompanyPageTitleProps> = ({ 
  primaryText, countryCode, companyType, primaryClassName = '', directoryEntry
}) => {
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const createdBy = [
    directoryEntry?.createdBy?.firstName,
    directoryEntry?.createdBy?.lastName
  ].filter(Boolean).join(' ')
  const createdAt = directoryEntry?.createdDate ? formatDateTimeDisplay(directoryEntry?.createdDate) : ''
  const updatedBy = [
    directoryEntry?.updatedBy?.firstName,
    directoryEntry?.updatedBy?.lastName
  ].filter(Boolean).join(' ')
  const updatedAt = directoryEntry?.createdDate ? formatDateTimeDisplay(directoryEntry?.updatedDate) : ''
  return (
    <div className="company-page-title flex justify-between items-center">
      <div className="font-noe-display flex items-center gap-[10px]">
        <span className="company-logo w-[60px] h-auto">
          <img src={companyLogoPlaceholder} alt="Company logo" className="w-[60px] h-[60px]" />
        </span>
        <div className="flex flex-col ">
          <div className="flex gap-[10px] items-baseline">
            <h1 className={classNames('primary-title font-noe-display text-[26px] leading-normal max-w-[70vw] text-black font-normal m-0 text-ellipsis overflow-hidden whitespace-nowrap', primaryClassName)}>
              {primaryText}
            </h1>
            {countryCode && <Flag code={countryCode} className="relative top-[2px] w-[18px] h-[18px]" />}
          </div>
          <p className="font-pt-sans text-[15px] text-black m-0">{companyType}</p>
        </div>
      </div>
      {(isDesktop || isDesktopHD) && (
        <div className="flex flex-col gap-[5px]">
          {createdBy && createdAt && (
            <p className="created-by m-0 font-pt-sans text-[13px] tracking-[0.52px] text-gray-100">
              Created by: <span className="underline">{createdBy}</span>, {createdAt}
            </p>
          )}
          {updatedBy && updatedAt && (
            <p className="created-by m-0 font-pt-sans text-[13px] tracking-[0.52px] text-gray-100">
              Modified by: <span className="underline">{updatedBy}</span>, {updatedAt}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
