import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countriesFilterSelector, typesFilterSelector, stagesFilterSelector, scoresFilterSelector, managerTextFilterSelector, managerFilterSelector, wholeFilterSelector, termFilterSelector } from 'store/modules/crm/selectors';
import { IMultiselectValueLabelPair, Multiselect } from 'ui/Multiselect';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import TextInput from 'pureUi/TextInput';
import MagnifyingIcon from 'ui/Icons/magnifying-glass.component.svg';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import FluidButton from 'ui/FluidButton';
import { CompanyIcon } from 'ui/Icons/components/Company.component';
import { theme } from 'styles';
import * as AuthSelectors from 'store/modules/auth';
import { useResponsive } from '../hooks/useResponsive';
import { ContactIcon } from 'ui/Icons/components/CrmContact.component';
import { useHistory } from 'react-router';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import * as CrmManagementActions from 'store/modules/crm/actions';
import { AsyncSearchDropdown } from 'ui/AsyncSearchDropdown';
import { userToOption } from 'ui/SingleSelect/helpers';
import { useBEUsers } from 'services/BackendApi/queries/useBEUsers';
import { initialState } from 'store/modules/crm/model';
import { delay } from 'utils/promise';

export interface ICrmFiltersProps {
  onSearch: () => void;
}


export const CrmFilters: React.FC<ICrmFiltersProps>= React.memo(({ onSearch }) => {
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const history = useHistory();
  const isSr = useSelector(AuthSelectors.isSr);
  const isSales = useSelector(AuthSelectors.isSales);
  
  const managerFilter = useSelector(managerFilterSelector);
  const managerQueryText = useSelector(managerTextFilterSelector);
  const typesFilter = useSelector(typesFilterSelector);
  const countriesFilter = useSelector(countriesFilterSelector);
  const stageFilter = useSelector(stagesFilterSelector);  
  const scoreFilter = useSelector(scoresFilterSelector)?.toString();
  const filter = useSelector(wholeFilterSelector);
  const profileSearchValue = useSelector(termFilterSelector);
  
  const [isFilterReset, setIsFilterReset] = useState<boolean>(false);
  
  const managers = useBEUsers(managerQueryText);
  const managerOptions: ISingleSelectOption[] = managers.data?.data?.data.map(user => userToOption(user)) ?? [];

  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const companyTypeOptions: IMultiselectValueLabelPair[] = crmTypeValuesCompanies.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value,
      iconHtml: <i className="fas fa-circle text-status-requested"></i>,
      iconAlign: 'end', })
  ) ?? [];

  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const contactTypeOptions: IMultiselectValueLabelPair[] = crmTypeValuesContacts.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value,
      iconHtml: <i className="fas fa-circle text-status-requested"></i>,
      iconAlign: 'end', })
  ) ?? []; 
  
  const crmCountries = useCrmCountries();
  const countryOptions: IMultiselectValueLabelPair[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const crmTypeValuesStages = useCrmTypeValues(ETypeValueType.LEAD_STAGE, { enabled: true });
  const stagesTypeOptions: ISingleSelectOption[] = crmTypeValuesStages.data?.data?.map(
    item => ({ 
      value: item.code, 
      label: item.value
    })
  ) ?? [];

  const scoresTypeOptions: ISingleSelectOption[] = [
    { value: '0', label: 'Not Set' },
    { value: '1', label: '1*' },
    { value: '2', label: '2*' },
    { value: '3', label: '3*' },
    { value: '4', label: '4*' },
    { value: '5', label: '5*' },
  ];
  
  useEffect(() => {
    const searchAgain = async () => {
      await delay(0); // react-query cache and react-redux are not exactly sync at this point
      onSearch();
      setIsFilterReset(false);
    }
    if (isFilterReset) {
      searchAgain();
    }
  },[filter, isFilterReset, onSearch]);

  const handleTypeFilterChange = useCallback((selectedValues: string[]) => {
    const selectedValuesTyped = selectedValues.map( v => ({
      value: v,
      profileType: companyTypeOptions.some( e => e.value === v) ? ETypeValueType.COMPANY : ETypeValueType.CONTACT,
    }));
    dispatch(CrmManagementActions.setTypeFilterAction(selectedValuesTyped));
  }, [dispatch, companyTypeOptions]);

  const handleCountryFilterChange = useCallback((selectedValues: string[]) => {
    dispatch(CrmManagementActions.setCountryFilterAction(selectedValues));
  }, [dispatch]);

  const handleStageFilterChange = useCallback((selectedValue?: string) => {
    dispatch(CrmManagementActions.setStageFilterAction(selectedValue || null));
  }, [dispatch]);

  const handleScoreFilterChange = useCallback((selectedValue?: string) => {
    dispatch(CrmManagementActions.setScoreFilterAction(selectedValue ? parseInt(selectedValue) : null));
  }, [dispatch]);

  const handleProfileSearchChange = useCallback((e) => {
    dispatch(CrmManagementActions.setTermFilterAction(e.currentTarget.value));
  }, [dispatch]);
  
  const handleManagerFilterChoose = useCallback((value: string) => {
    const selectedManager = managerOptions.find(item => item.value === value);
    dispatch(CrmManagementActions.setManagerTextFilterAction(selectedManager?.label ?? ''));
    dispatch(CrmManagementActions.setManagerFilterAction(value));
  }, [managerOptions, dispatch]);
  
  const handleManagerFilterChange = useCallback(async value => {
    if (value === '') {
      dispatch(CrmManagementActions.setManagerFilterAction(null));
    }
    dispatch(CrmManagementActions.setManagerTextFilterAction(value || null));
  }, [dispatch]);

  const handleResetFilters = useCallback(() => {
    setIsFilterReset(true);
    dispatch(CrmManagementActions.resetWholeFilterAction(initialState.filters));
  }, [dispatch]);

  const handleSearch = useCallback(() => {
    onSearch();
  }, [onSearch]);

  const handleCreateCompany = useCallback(() => {
    history.push('/crm/company/create');
  }, [history]);

  const handleCreateContact = useCallback(() => {
    history.push('/crm/contact/create');
  }, [history]);

  return (
    <div className="task-list-filters mt-5 mb-5">
      <p className="task-list-filters-header font-pt-sans font-bold text-17px leading-22px">Filter Results</p>
      <div className="filters-container flex flex-wrap gap-x-[20px] gap-y-[15px] mt-10px">
        <TextInput
          id={'crm-filter-profile'}
          onChange={handleProfileSearchChange}
          placeholder='Search from directory ...'
          className='task-priority-filter min-w-[180px] max-w-[180px]'
          value={profileSearchValue}
          inputClassName='!py-[10px] !px-[30px] !text-black !bg-white-hard'
        >
          <MagnifyingIcon className='absolute w-[18px] top-[10px] left-[10px] fill-light-gray rotate-90' />
        </TextInput>

        <div className="task-category-filter flex-1 min-w-[180px] max-w-[180px] relative top-[-2px]">
          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs">Type</label>
          <Multiselect
            className="multiselect-category bg-ivory mt-[4px] text-15px"
            selectedItemContentClassName="font-bold"
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-ivory"
            onUpdate={handleTypeFilterChange}
            options={[...companyTypeOptions, ...contactTypeOptions]}
            selectedValues={typesFilter.map( e => e.value )}
            isIncludeClearButton
          />
        </div>
        
        {!isSales && (<AsyncSearchDropdown
          fieldId="manager"
          label="Manager"
          className="manager min-w-[180px] flex-1"
          value={managerQueryText ?? ''}
          selectedValue={managerFilter ?? undefined}
          options={managerOptions}
          onChoose={handleManagerFilterChoose}
          onChange={handleManagerFilterChange}
          maxVisibleItems={8}
          loading={managers.isLoading}
          errorMessage={null}
          errorClassName="assignee-error"
        />)}

        <div className="task-category-filter flex-1 min-w-[250px] max-w-[250px] relative top-[-2px]">
          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs">Country</label>
          <Multiselect
            selectedItemContentClassName="font-bold"
            className="multiselect-status bg-ivory mt-[4px] text-15px"
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-ivory"
            onUpdate={handleCountryFilterChange}
            options={countryOptions}
            selectedValues={countriesFilter}
            isIncludeClearButton
          />
        </div>

        <SingleSelect
          fieldId="task-priority-filter"
          label="Stage"
          className="task-priority-filter min-w-[180px] max-w-[180px]"
          labelWhenNothingSelected="All"
          useCustomLabelWhenNothingSelected
          value={stageFilter ?? undefined}
          options={stagesTypeOptions}
          onChange={handleStageFilterChange}
          maxVisibleItems={6}
        />

        <SingleSelect
          fieldId="task-priority-filter"
          label="Score above"
          className="task-priority-filter min-w-[180px] max-w-[180px]"
          labelWhenNothingSelected="All"
          useCustomLabelWhenNothingSelected
          value={scoreFilter ?? undefined}
          options={scoresTypeOptions}
          onChange={handleScoreFilterChange}
          maxVisibleItems={6}
        />
      </div>
      <div className="button-container flex justify-between mt-[15px]">
        <div className="buttons-container-left flex items-center gap-[10px]">
          <FluidButton type="secondary" textClassName="flex items-center gap-[10px]" onClick={handleResetFilters}>
            {isMobile ? 'Reset' : 'Reset Filters'}
          </FluidButton>
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleSearch}>
            Search
          </FluidButton>
        </div>
        <div className="buttons-container-right flex items-center gap-[10px]">
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateCompany} disabled={isSr}>
            New Company
            {isMobile ? null : <CompanyIcon fill={theme.colors['white']} />}
          </FluidButton>
          <FluidButton type="primary" textClassName="flex items-center gap-[10px]" onClick={handleCreateContact} disabled={isSr}>
            New Contact
            {isMobile ? null : <ContactIcon fill={theme.colors['white']} />}
          </FluidButton>
        </div>
      </div>
    </div>
  );
});
