import classnames from 'classnames';
import { Field, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import * as AuthSelectors from 'store/modules/auth/selectors';
import Editor from 'pureUi/Editor';
import React, { useCallback, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import { useSelector } from 'react-redux';
import { useBEUsers } from 'services/BackendApi/queries/useBEUsers';
import { userToOption } from 'ui/SingleSelect/helpers';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { companyMainInfoValidationSchema, ICrmCompanyMainInfoFormValues } from './crmCompanyMainInfoForm';
import { IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';
import { useUpdateCompanyMutation } from 'services/CrmApi/mutations/useUpdateCompanyMutation';
import { IUpdateCompanyRequest } from 'services/CrmApi/types/CrmCompanyTypes';
import { delay } from 'utils';
import { useNotifications } from 'hooks/useNotifications';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useQueryClient } from '@tanstack/react-query';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';

interface ICrmCompanyMainInfoProps {
  directoryEntryId: string;
}

export const CrmCompanyMainInfo: React.FC<ICrmCompanyMainInfoProps> = ({ directoryEntryId }) => {
  const { isMobile } = useResponsive();
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();

  const isSr = useSelector(AuthSelectors.isSr);
  const canEdit = !isSr;
  const beUsers = useBEUsers('');
  const managerOptions: ISingleSelectOption[] = beUsers?.data?.data?.data
    ?.map(user => userToOption(user)) ?? [];

  const queryClient = useQueryClient();
  const { crmDirectoryEntryResponse, directoryEntry } = useCrmDirectoryEntry(directoryEntryId);
  const updateCompanyMutation = useUpdateCompanyMutation();
  const isLoading = queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntryId] }) > 0;
  const isSaving = updateCompanyMutation.isPending;

  const handleFormSubmit = useCallback(async (values: ICrmCompanyMainInfoFormValues, formikHelpers: FormikHelpers<ICrmCompanyMainInfoFormValues>) => {
    const requestData : IUpdateCompanyRequest = {
      ...values,
      directoryId: directoryEntryId,
    }

    try {
      await updateCompanyMutation.mutateAsync(requestData);
      showSuccessNotification('Company updated.');      
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to update a company');
      await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      formikHelpers.resetForm();
    }
  }, [defaultOnErrorHandler, directoryEntryId, queryClient, showSuccessNotification, updateCompanyMutation]);

  if (beUsers.isPending || crmDirectoryEntryResponse.isPending) {
    return (
      <div className="w-full flex justify-center mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (!directoryEntry || !('companyType' in directoryEntry.profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a company profile" />
      </div>
    )
  }

  const initialValues: ICrmCompanyMainInfoFormValues = {
      managerId: directoryEntry.manager?.id ?? null,
      keyNotes: directoryEntry.keyNotes ?? '',
  };
  
  return (
    <div className="main-info-container w-full px-[10px] pt-[15px]">
      <h3 className="section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0">
        Main Info
      </h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={companyMainInfoValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<ICrmCompanyMainInfoFormValues>) => {  
          return (
            <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] ">
              <Field name="managerId">
                {({ field: { name, value }, form: { setFieldValue } }) => (
                  <SingleSelect
                    fieldId="manager"
                    label="Manager/Owner *"
                    className="manager"
                    value={value}
                    showSelectedOption
                    maxVisibleItems={isMobile ? 4 : 6}
                    scrollToSelectedItem
                    options={managerOptions ?? []}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                    disabled={!canEdit || isLoading || isSaving}
                    errorMessage={form.touched.managerId && form.errors.managerId ? form.errors.managerId : null}
                    errorClassName="manager-error"
                  />
                )}
              </Field>
              <div className="key-notes flex  gap-[20px]">
                <Field name="keyNotes">
                  {({ field: { name, value }, form: { setFieldValue } }) => (
                    <div className="flex flex-col w-full">
                      <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                        Key Notes
                      </label>
                      <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} disabled={!canEdit || isLoading || isSaving} />
                    </div>
                  )}
                </Field>
              </div>

              <div className="buttons-container ">
                <FluidButton type="primary" textClassName="flex items-center gap-[10px]" isLoading={isLoading || isSaving} disabled={!canEdit} onClick={form.submitForm}>
                    Save
                </FluidButton>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}