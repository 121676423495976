import { Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { ProfileInfo } from './ProfileInfo';
import { IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';
import { companyProfileFormValidationSchema, ICompanyProfileFormValues } from './companyProfileForm';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { useUpdateCompanyMutation } from 'services/CrmApi/mutations/useUpdateCompanyMutation';
import { IUpdateCompanyRequest } from 'services/CrmApi/types/CrmCompanyTypes';
import { useNotifications } from 'hooks/useNotifications';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useQueryClient } from '@tanstack/react-query';

interface ICrmCompanyEditProfileParams {
  directoryEntry: IDirectoryEntry;
}

export const CrmCompanyEditProfile: React.FC<ICrmCompanyEditProfileParams> = ({ directoryEntry }) => {
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const profile = directoryEntry.profile;

  const queryClient = useQueryClient();
  const updateCompanyMutation = useUpdateCompanyMutation();

  const handleFormSubmit = useCallback(async (values: ICompanyProfileFormValues, formikHelpers: FormikHelpers<ICompanyProfileFormValues>) => {
    const requestData : IUpdateCompanyRequest = {
      directoryId: directoryEntry.id,
      profile: {
        companyTypeCode: values.companyTypeCode,
        tcType: values.tcType,
        industry: values.industry,
        employeeCount: values.employeeCount.length > 0 ? Number(values.employeeCount) : null,
        website: values.website,
      },
      email: values.email.length > 0 ? values.email : null,
      countryCode: values.countryCode ?? null,
      acceptsEmail: values.acceptsEmail,
      mobile: values.mobile,
      address: values.address,
      linkedin: values.linkedin,
      twitter: values.twitter,
      instagram: values.instagram,
    }

    try {
      await updateCompanyMutation.mutateAsync(requestData);
      showSuccessNotification('Company updated.');
      queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntry.id] })
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to update a company');
      queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntry.id] })
      formikHelpers.resetForm();
    }
  }, [defaultOnErrorHandler, directoryEntry.id, queryClient, showSuccessNotification, updateCompanyMutation]);

  if (!('companyType' in profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a company profile" />
      </div>
    )
  }

  const initialValues: ICompanyProfileFormValues = {
      companyTypeCode: profile.companyType?.code ?? '',
      name: directoryEntry?.fullName ?? '',
      tcType: profile?.tcType,
      industry: profile?.industry ?? '',
      employeeCount: !profile.employeeCount ? '' : String(profile.employeeCount),
      countryCode: directoryEntry?.country?.code ?? null,
      address: directoryEntry?.address ?? '',
      mobile: directoryEntry?.mobile ?? '',
      email: directoryEntry?.email ?? '',
      acceptsEmail: directoryEntry?.acceptsEmail ?? false,
      website: profile?.website ?? '',
      linkedin: directoryEntry?.linkedin ?? '',
      twitter: directoryEntry?.twitter ?? '',
      instagram: directoryEntry?.instagram ?? '',
  };
  
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={companyProfileFormValidationSchema}
      onSubmit={handleFormSubmit}
    >
      <ProfileInfo isSaving={updateCompanyMutation.isPending || queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntry.id] }) > 0} />
    </Formik>
  )
};
