import { Slider } from '@pure-escapes/webapp-ui-components';
import styled from 'styled-components';
import { theme } from 'styles';

export const FixedSlider = styled(Slider)`
  ${props => props.theme.breakpoints.tablet`
    .slick-list, .slick-track, .slick-slide, .slick-slide > div {
      height: 100%;
    }

    .slick-slide img {
      height: 100%;
      object-fit: cover;
    }

  `}
`;

export const MainSlider = styled(FixedSlider)`
  ${props => props.theme.breakpoints.tablet`
    height: 500px;
  `}
`;

export const NavSlider = styled(FixedSlider)`
  padding: 0;

  .slick-track {
    margin: 0;
  }

  .slick-arrow {
    z-index: 1;
  }

  .slick-slide {
    padding: ${theme.spacing.gutter / 2}px;

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }

  ${props => props.theme.breakpoints.tablet`
    height: 100px;
  `}
`;
