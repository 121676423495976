import * as Actions from './actions';
import { ICrmDomain, initialState } from './model';

export const crmReducer = (
  state: ICrmDomain = initialState,
  action: Actions.CrmAction
): ICrmDomain => {
  switch (action.type) {
    case Actions.SET_TYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          typeCodes: action.value,
        },
      };
    case Actions.SET_COUNTRY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          countryCodes: action.value,
        },
      };
    case Actions.SET_STAGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          leadStageCode: action.value,
        },
      };
    case Actions.SET_SCORE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          leadScoreGte: action.value,
        },
      };
    case Actions.SET_MANAGER_TEXT_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          managerText: action.value,
        },
      };
    case Actions.SET_MANAGER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          managerId: action.value,
        },
      };
    case Actions.SET_TERM_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          term: action.value,
        },
      };
    case Actions.RESET_WHOLE_FILTER:
      return {
        ...state,
        data: {
          ...initialState.data,
        },
        filters: {
          ...initialState.filters,
        },
      };
    default:
      return state;
  }
};
