import { ETasksSortableKey } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { ESortOrder } from 'store/common/types';
import { IFilter } from 'services/CrmApi/types/DirectoryEntry';

export interface ICrmDomain {
  filters: IFilter,
  data: {
    managerText: string;
  },
  pagination: {
    currentPage: number;
    itemsPerPage: number; 
    totalItems: number | null; 
  },
  sort: {
    sortBy: ETasksSortableKey;
    sortOrder: ESortOrder; 
  },
}

export const initialState: ICrmDomain = {
  filters: {
    term: '',
    typeCodes: [],
    managerId: null,
    countryCodes: [],
    leadStageCode: null,
    leadScoreGte: null,
  },
  data: {
    managerText: '',
  },
  pagination: {
    currentPage: 0,
    itemsPerPage: 10,
    totalItems: null,
  },
  sort: {
    sortBy: ETasksSortableKey.DEADLINE,
    sortOrder: ESortOrder.ASC,
  }
};
