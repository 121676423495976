import React from 'react';
import { BackLink } from 'ui/BackLink';
import classnames from 'classnames';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { VerticalSpace } from 'ui/VerticalSpace';
import { useHistory, useRouteMatch } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';
import { ECrmDirectoryEntryType, ICrmCompanyProfile } from 'services/CrmApi/types/DirectoryEntry';
import { CrmCompanyTabs } from './CrmCompanyTabs/CrmCompanyTabs';
import { CompanyPageTitle } from '../components/CompanyPageTitle';
import { CrmCompanyEditProfile } from './CrmCompanyProfile/CrmCompanyEditProfile';

interface ICrmCompanyEditRouteParams {
  companyId: string;
}

export const CrmCompanyEdit = () => {
  const match = useRouteMatch<ICrmCompanyEditRouteParams>();
  const companyId = match.params.companyId;
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();

  const crmCountries = useCrmCountries();
  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const { crmDirectoryEntryResponse, directoryEntry } = useCrmDirectoryEntry(companyId);

  const layoutClassname = classnames('flex gap-[20px]', {
    'flex-col': isMobile || isTablet,
    'flex-row': isDesktop || isDesktopHD,
  });

  if (crmCountries.isPending || crmTypeValuesCompanies.isPending || crmDirectoryEntryResponse.isPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesCompanies.isError || !directoryEntry || !directoryEntry.profile) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }
 
  if (directoryEntry.profile.type !== ECrmDirectoryEntryType.COMPANY || !('companyType' in directoryEntry.profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a company profile" />
      </div>
    )
  }

  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <CompanyPageTitle
        primaryText={directoryEntry?.fullName ?? ''}
        countryCode={directoryEntry?.country?.code}
        directoryEntry={directoryEntry}
        companyType={directoryEntry?.profile.companyType.value}
      />
      <VerticalSpace height="20px" />
      <div className={layoutClassname}>
        <CrmCompanyEditProfile directoryEntry={directoryEntry} />
        <CrmCompanyTabs directoryEntry={directoryEntry} />
      </div>
    </div>
  )
};
