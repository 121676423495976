import * as Yup from 'yup';

export const companyMainInfoValidationSchema = Yup.object().shape({
  managerId: Yup.string().nullable(true).required('Field is required'),
  keyNotes: Yup.string(),
});

export interface ICrmCompanyMainInfoFormValues {
  managerId: string | null;
  keyNotes: string;
}
