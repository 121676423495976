import React, { useState } from 'react';
import classNames from 'classnames';
import { BookingAccommodation } from './BookingAccommodation';
import { BookingNonAccommodationProduct } from './BookingNonAccommodationProduct';
import { BulletPoints } from 'ui/BulletPoints/BulletPoints';
import {
  composeCancellationPolicyFromRoomExpenseInfo,
  extractCancellationPolicies,
} from 'common-lib/cancellation-policy-composer';
import { addDays, format } from 'date-fns';
import { BookingBuilder, EUploadTag, } from 'services/BackendApi';
import { BasketRateTypes, LodgingSummary } from 'interfaces';
import { getCurrencySymbol } from 'utils';
import { flatten, uniqBy } from 'ramda';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import AnimateHeight from 'react-animate-height';
import { SvgIcon } from 'ui/SvgIcon';
import ArticleIcon from 'ui/Icons/components/Article.component';
import { theme } from '../../../tailwind.config';
import { VerticalSpace } from 'ui/VerticalSpace';
import { openLink } from './helpers';
import { getCancellationPoliciesFromResponse } from 'store/modules/bookingBuilder/utils';

interface ITitleProps {
  children: React.ReactNode;
  className?: string;
} 

const Title: React.FC<ITitleProps> = React.memo(({ children, className }) => {
  return (
    <p
      className={classNames(
        'nap-title font-hurmegeometric-sans text-brown-prime text-13px leading-16px uppercase font-semibold m-0',
        className
      )}
    >
      {children}
    </p>
  );
});

type OfferTermsItem = {
  name: string;
  termsAndConditions: string;
};

interface IBookingAccommodationExpandInfoProps {
  booking: BookingBuilder;
  build: IBasketBuildL4;
  lodgingSummaries: LodgingSummary[];
}

export const BasketItemExpandableInfo: React.FC<IBookingAccommodationExpandInfoProps> = React.memo(
  ({ booking, build, lodgingSummaries }) => {
    const [areDestinationExpanded, setDestinationExpanded] = useState(false);
    const [areResortDetailsExpanded, setResortDetailsExpanded] = useState(false);
    const [areBrochuresExpanded, setBrochuresExpanded] = useState(false);
    const [arePoliciesAndRestrictionsExpanded, setPoliciesAndRestrictionsExpanded] = useState(false);
    const [areCancellationPoliciesExpanded, setCancellationPoliciesExpanded] = useState(false);
    const [arePaymentTermsExpanded, setPaymentTermsExpanded] = useState(false);
    const [areMembershipBenefitsExpanded, setMembershipBenefitsExpanded] = useState(false);
    const [areOfferTermsExpanded, setOfferTermsExpanded] = useState(false);
    const brochures = build.uploads.filter(item => item.tag === EUploadTag.BROCHURE && item.ownerType !== 'Destination');
    const destinations = build.uploads.filter(item => item.tag === EUploadTag.BROCHURE && item.ownerType === 'Destination');
    const startDate = booking.request.startDate;
    const endDate = format(addDays(new Date(booking.request.endDate), 1), 'yyyy-MM-dd');
    const otherItems = [
      ...booking.response.availableProductSets.Supplement,
      ...booking.response.availableProductSets.Fine,
    ];

    // the rateType is static or live, so its OK to use the first accommodation as they're always the same
    const cancellationPolicies = getCancellationPoliciesFromResponse(booking.response, booking.response.potentialBooking.Accommodation[0].rateType as BasketRateTypes);

    const accommodations = booking.response.potentialBooking.Accommodation;
    const fines = flatten(booking.response.availableProductSets.Fine.filter(item => item.selected).map(available => available.breakdown));
    const groundServices = flatten(booking.response.availableProductSets['Ground Service'].filter(item => item.selected).map(available => available.breakdown));
    const supplements = flatten(booking.response.availableProductSets.Supplement.filter(item => item.selected).map(available => available.breakdown));
    const transfers = flatten(booking.response.availableProductSets.Transfer.filter(item => item.selected).map(available => available.breakdown));
    // long hand, but gives us TS help
    let allPaymentTerms: string[] = [];
    allPaymentTerms = allPaymentTerms.concat(accommodations.map(product => product.paymentTerms));
    allPaymentTerms = allPaymentTerms.concat(fines.map(product => product.paymentTerms));
    allPaymentTerms = allPaymentTerms.concat(groundServices.map(product => product.paymentTerms));
    allPaymentTerms = allPaymentTerms.concat(supplements.map(product => product.paymentTerms));
    allPaymentTerms = allPaymentTerms.concat(transfers.map(product => product.paymentTerms));
    const paymentTerms = uniqBy(a => a, flatten(allPaymentTerms).filter(Boolean))
    let allOfferTerms: OfferTermsItem[] = [];
    // @ts-ignore
    allOfferTerms = allOfferTerms.concat(accommodations.map(product => product.offers.map(item => ({ name: item.offer.name, termsAndConditions: item.offer.termsAndConditions}))));
    // @ts-ignore
    allOfferTerms = allOfferTerms.concat(fines.map(product => product.offers.map(item => ({ name: item.offer.name, termsAndConditions: item.offer.termsAndConditions}))));
    // @ts-ignore
    allOfferTerms = allOfferTerms.concat(groundServices.map(product => product.offers.map(item => ({ name: item.offer.name, termsAndConditions: item.offer.termsAndConditions}))));
    // @ts-ignore
    allOfferTerms = allOfferTerms.concat(supplements.map(product => product.offers.map(item => ({ name: item.offer.name, termsAndConditions: item.offer.termsAndConditions}))));
    // @ts-ignore
    allOfferTerms = allOfferTerms.concat(transfers.map(product => product.offers.map(item => ({ name: item.offer.name, termsAndConditions: item.offer.termsAndConditions}))));
    const offerTerms = uniqBy(a => a, flatten(allOfferTerms).filter(Boolean));

    return (
      <div className="booking-expand-info mt-5 bg-white border border-solid border-gray-20">
        {booking.response.availableProductSets.Accommodation.map((accommodation, accommodationIndex) => {
          return (
            <BookingAccommodation
              key={accommodation.products[0].uuid}
              booking={booking}
              accommodation={accommodation}
              accommodationIndex={accommodationIndex}
              lodgingSummaries={lodgingSummaries}
              build={build}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}

        {booking.response.availableProductSets.Transfer.filter(item => item.selected).length > 0 && (
          <BookingNonAccommodationProduct
            title="Transfers"
            products={booking.response.availableProductSets.Transfer}
            currencyCode={booking.response.currency}
            className="transfer"
            build={build}
          />
        )}

        {booking.response.availableProductSets['Ground Service'].filter(item => item.selected).length > 0 && (
          <BookingNonAccommodationProduct
            title="Ground Services"
            products={booking.response.availableProductSets['Ground Service']}
            currencyCode={booking.response.currency}
            className="ground-service"
          />
        )}

        {otherItems.filter(item => item.selected).length > 0 && (
          <BookingNonAccommodationProduct
            title="Other Items"
            products={otherItems}
            currencyCode={booking.response.currency}
            className="other-items"
          />
        )}

        <VerticalSpace height="10px" />

        {booking.response.hotel?.destination && <div className="destinations py-[5px] px-5">
          <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setDestinationExpanded(value => !value)}>
            <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areDestinationExpanded ? '-' : '+'}</span>
            <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Destination</span>
          </div>
          <AnimateHeight duration={300} height={areDestinationExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
            <div className="flex flex-wrap gap-x-[15px]">
              {destinations.map(destination => (
                <div key={destination.uuid} className="extra-detail-item flex items-start gap-[3px] cursor-pointer mt-[8px]" onClick={() => openLink(destination.url)}>
                  <span
                    className="flex justify-center items-center cursor-pointer hover:bg-gray-20 relative top-[2px]"
                  >
                    <SvgIcon
                      IconComponent={ArticleIcon}
                      defaultFill={theme.colors['brown-100']}
                      hoverFill={theme.colors['brown-hard']}
                      defaultBackground={theme.colors['transparent']}
                      hoverBackground={theme.colors['gray-20']}
                      width="16px"
                      height="16px"
                    />
                  </span>
                  <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline ml-[3px]">{destination.displayName}</span>
                </div>
              ))}
            </div>
          </AnimateHeight>
        </div>}

        {(booking.response.hotel?.overview?.length || booking.response.hotel?.amenities?.length || booking.response.hotel?.additionalInfo?.length) && (
          <div className="resort-details py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px] select-none" onClick={() => setResortDetailsExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areResortDetailsExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Resort Details</span>
            </div>
            <AnimateHeight duration={300} height={areResortDetailsExpanded ? 'auto' : 0} className={classNames('accordion-area pl-[15px]', {})}>
              {booking.response.hotel?.overview?.length ? (
                <>
                  <p className="font-hurmegeometric-sans text-flint text-[13px] leading-normal font-[700] uppercase mt-[10px] m-0 mb-[5px]">Overview</p>
                  {booking.response.hotel.overview.map((item: string) => (
                    <p key={item} className="font-hurmegeometric-sans text-flint text-[13px] leading-[20px] font-[400] m-0">{item}</p>
                  ))}
                  
                </>
              ) : null}
              {booking.response.hotel?.amenities?.length ? (
                <>
                <p className="font-hurmegeometric-sans text-flint text-[13px] leading-normal font-[700] uppercase mt-[10px] m-0 mb-[5px]">Amenities</p>
                  {booking.response.hotel.amenities.map((item: string) => (
                    <p key={item} className="font-hurmegeometric-sans text-flint text-[13px] leading-[20px] font-[400] m-0">{item}</p>
                  ))}
                </>
              ) : null}
              {booking.response.hotel?.additionalInfo?.length ? (
                <>
                  <p className="font-hurmegeometric-sans text-flint text-[13px] leading-normal font-[700] uppercase mt-[10px] m-0 mb-[5px]">THINGS TO BE AWARE OF</p>
                  <p className="font-hurmegeometric-sans text-flint text-[13px] leading-[20px] font-[400] m-0">{booking.response.hotel.additionalInfo}</p>
                </>
              ) : null}
            </AnimateHeight>
          </div>
        )}

        {brochures.length > 0 && <div className="brochures py-[5px] px-5">
          <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setBrochuresExpanded(value => !value)}>
            <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areBrochuresExpanded ? '-' : '+'}</span>
            <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Brochures</span>
          </div>
          <AnimateHeight duration={300} height={areBrochuresExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
            <div className="flex flex-wrap gap-x-[15px]">
              {brochures.map(brochure => (
                <div key={brochure.uuid} className="extra-detail-item flex items-start gap-[3px] cursor-pointer mt-[8px]" onClick={() => openLink(brochure.url)}>
                  <span
                    className="flex justify-center items-center cursor-pointer hover:bg-gray-20 relative top-[2px]"                    
                  >
                    <SvgIcon
                      IconComponent={ArticleIcon}
                      defaultFill={theme.colors['brown-100']}
                      hoverFill={theme.colors['brown-hard']}
                      defaultBackground={theme.colors['transparent']}
                      hoverBackground={theme.colors['gray-20']}
                      width="16px"
                      height="16px"
                    />
                  </span>
                  <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline ml-[3px]">{brochure.displayName}</span>
                </div>
              ))}
            </div>
          </AnimateHeight>
        </div>}

        {booking.response.hotel.policiesAndRestrictions?.length ? (
          <div className="policies-and-restrictions py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setPoliciesAndRestrictionsExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{arePoliciesAndRestrictionsExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Policies & Restrictions</span>
            </div>
            <AnimateHeight duration={300} height={arePoliciesAndRestrictionsExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
              <div className="policies-restrictions mt-[5px]">
                <BulletPoints
                  textLines={booking.response.hotel.policiesAndRestrictions}
                  ulClassname=" pl-[12px] mt-[5px]"
                  liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                />
              </div>
            </AnimateHeight>
          </div>
        ) : null}

        {cancellationPolicies?.length ? (
          <div className="cancellation-policies py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setCancellationPoliciesExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areCancellationPoliciesExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Cancellation Policies</span>
            </div>
            <AnimateHeight duration={300} height={areCancellationPoliciesExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
              <div className="cancellation-policies mt-[5px]">
                <ul className="mt-[5px] pl-[12px]">
                  {booking.response.expenseInfosGroupedByRoom.map((item, index) => {
                    const cancellationPoliciesLines = composeCancellationPolicyFromRoomExpenseInfo(item, {
                      currencySymbol: getCurrencySymbol(booking.response.currency),
                      appendLines: ['*at 00.00 time at destination'],
                    });
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        Accommodation {index + 1}
                        <BulletPoints
                          textLines={cancellationPoliciesLines}
                          ulClassname=" pl-[12px] mt-[5px] list-disc m-0 p-0"
                          liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                        />
                      </li>
                    );
                  })}
                  {extractCancellationPolicies(fines).map((product, index) => {
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        {product.cancellationPolicy}
                      </li>
                    );
                  })}
                  {extractCancellationPolicies(groundServices).map((cp, index) => {
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        {cp}
                      </li>
                    );
                  })}
                  {extractCancellationPolicies(supplements).map((cp, index) => {
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        {cp}
                      </li>
                    );
                  })}
                  {extractCancellationPolicies(transfers).map((cp, index) => {
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        {cp}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </AnimateHeight>
          </div>
        ) : null}

        {paymentTerms?.length ? (
          <div className="policies-and-restrictions py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setPaymentTermsExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{arePaymentTermsExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Payment Terms</span>
            </div>
            <AnimateHeight duration={300} height={arePaymentTermsExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
              <div className="payment-terms mt-[5px]">
                <BulletPoints
                  textLines={paymentTerms}
                  ulClassname=" pl-[12px] mt-[5px]"
                  liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                />
              </div>
            </AnimateHeight>
          </div>
        ) : null}

        {offerTerms?.length ? (
          <div className="offer-terms py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setOfferTermsExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areOfferTermsExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Offer Terms</span>
            </div>
            <AnimateHeight duration={300} height={areOfferTermsExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
              <div className="offer-terms mt-[5px]">
                <ul className="mt-[5px] pl-[12px]">
                  {offerTerms.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0"
                      >
                        {item.name}
                        <BulletPoints
                          textLines={item.termsAndConditions.split('\n')}
                          ulClassname="pl-[12px] mt-[5px] list-disc m-0 p-0"
                          liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </AnimateHeight>
          </div>
        ) : null}

        {/* if the build has a company membership, show it */}
        {build.companyMembership && 
          <div className="membership-benefits py-[5px] px-5">
            <div className="details-heading flex items-center cursor-pointer gap-[10px]" onClick={() => setMembershipBenefitsExpanded(value => !value)}>
              <span className="details-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">{areMembershipBenefitsExpanded ? '-' : '+'}</span>
              <span className="details-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">Membership Benefits</span>
            </div>
            <AnimateHeight duration={300} height={areMembershipBenefitsExpanded ? 'auto' : 0} className={classNames('accordion-area px-[15px]', {})}>
              <div className='font-hurmegeometric-sans text-flint text-[13px] leading-normal my-5px'>
                {build.companyMembership.name}: {build.companyMembership.benefitsDescription}
              </div>
            </AnimateHeight>
          </div>
        }
      </div>
    );
  }
);
